import { Image, Link, OnVisible } from 'components';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import * as styles from './styles.module.scss';

const PartnerList = () => {
  const partnersQuery = graphql`
    query settingsQuery {
      prismicSettings {
        data {
          partners {
            interest_rate
            max_interest_rate
            business_interest_rate
            max_business_interest_rate
            person_interest_rate
            max_personal_interest_rate
            lender
            logo {
              url
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED, width: 400, placeholder: NONE)
                }
              }
            }
          }
        }
      }
    }
  `;

  const data = useStaticQuery(partnersQuery);
  const { prismicSettings } = data;
  const { data: settingsData } = prismicSettings;
  const { partners } = settingsData;

  const [partnersToUse, setPartnersToUse] = useState(partners);
  const [logosVisible, setLogosVisible] = useState(false);

  // if not enough items to complete a row (- see all lenders tile)
  // then remove the incomplete row
  const remainder = partners.length % 6;
  const incompleteRow = remainder < 5;

  useEffect(() => {
    if (incompleteRow) {
      const clonedPartners = [...partners];
      clonedPartners.splice(-`${remainder}`, `${remainder}`);
      setPartnersToUse(clonedPartners);
    }
  }, []);

  function customSort(arr) {
    const includeUseRate = arr.map((lender) => {
      return {
        ...lender,
        useRate: lender.interest_rate || lender.business_interest_rate || lender.person_interest_rate,
      };
    });

    return includeUseRate.sort((a, b) => (a.useRate > b.useRate ? 1 : -1));
  }

  return (
    <div className={styles.PartnersList}>
      {customSort(partnersToUse).map((partner) => {
        const {
          interest_rate: interest,
          max_interest_rate: maxInterestRate,
          logo,
          text,
          url,
          lender,
          person_interest_rate: perInterest,
          max_personal_interest_rate: maxPersonalInterestRate,
        } = partner;

        const interestToUse = interest || perInterest;
        const maxInterestRateToUse = maxInterestRate || maxPersonalInterestRate;
        return (
          <OnVisible className={styles.partner} key={logo?.url || text} onChange={setLogosVisible}>
            {logo?.url && logosVisible && (
              <Image className={styles.logo} image={logo} alt={lender} objectFit="contain" />
            )}
            {interestToUse && (
              <span className={styles.interest}>
                Interest rate:
                {maxInterestRateToUse != null ? (
                  <>
                    <br />
                    {interestToUse?.toFixed(2)}% - {maxInterestRateToUse?.toFixed(2)}%
                  </>
                ) : (
                  `${interestToUse?.toFixed(2)}%`
                )}
              </span>
            )}
            {/* FINAL TILE */}
            {text && url && (
              <Link className={styles.lastTile} to={url}>
                {text}
              </Link>
            )}
          </OnVisible>
        );
      })}
      {partners.length % 6 === 5 && (
        <div className={styles.partner}>
          <Link className={styles.lastTile} to="/lenders/">
            See All Lenders
          </Link>
        </div>
      )}
    </div>
  );
};

export default PartnerList;
