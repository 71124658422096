import { CtaLink, Image, Section } from 'components';
import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';

import * as styles from './styles.module.scss';

const LenderTable = (props) => {
  const { data } = props;
  const { primary } = data;
  const { title, lowest_to_highest: lowToHigh, type } = primary;
  // PARTNER DATA FROM SETTINGS
  const partnersQuery = graphql`
    query {
      ...GlobalPartnerData
    }
  `;

  const [lenderType, setLenderType] = useState('personal');

  const {
    prismicSettings: { data: partnerData },
  } = useStaticQuery(partnersQuery);

  const { partners } = partnerData;
  // content checks
  const titleHtml = title?.html;

  // toggle business & personal
  const handleOnClick = (event, lender) => {
    event.preventDefault();
    setLenderType(lender);
  };

  const showPersonalLoans = type === 'Personal Loans';

  return (
    <Section sliceName="LenderTable" className={`lender-table ${styles.lenderTable}`}>
      {titleHtml && <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleHtml }} />}
      {/*  toggle buttons */}
      {!showPersonalLoans && (
        <div className={styles.buttonsContainer}>
          <button
            type="button"
            className={`button ${styles.button} ${lenderType === 'personal' && styles.buttonActive}`}
            onClick={(event) => handleOnClick(event, 'personal')}
          >
            Personal
          </button>
          <button
            type="button"
            className={`button ${styles.button} ${lenderType === 'business' && styles.buttonActive}`}
            onClick={(event) => handleOnClick(event, 'business')}
          >
            Business
          </button>
        </div>
      )}
      {/* comparison table */}
      <Table partners={partners} lenderType={lenderType} sort={lowToHigh} primary={primary} type={type} />
      {/* cta only shows on mobile */}
      <CtaLink {...primary} className={styles.cta} fallbackUrl={CtaLink?.url} fallbackText="Find my rates" />
    </Section>
  );
};

export const Table = ({ partners, lenderType, sort, primary, type }) => {
  const isBusinessList = lenderType === 'business';
  const isPersonalList = type === 'Personal Loans';
  const vehicleLenders = partners.filter((partner) => partner.interest_rate);
  const businessLenders = partners.filter((partner) => partner.business_interest_rate);
  const personalLenders = partners.filter((partner) => partner.person_interest_rate);
  const sortedBusiness = businessLenders.sort((a, b) => (a.business_interest_rate > b.business_interest_rate ? 1 : -1));
  const sortedVechicle = vehicleLenders.sort((a, b) => (a.interest_rate > b.interest_rate ? 1 : -1));
  const sortedPersonal = personalLenders.sort((a, b) => (a.person_interest_rate > b.person_interest_rate ? 1 : -1));

  let typeToUse = isBusinessList ? businessLenders : vehicleLenders; // vanilla business or personal
  let sortedToUse = isBusinessList ? sortedBusiness : sortedVechicle; // sorted business or personal

  if (isPersonalList) {
    typeToUse = personalLenders;
    sortedToUse = sortedPersonal;
  }

  const sortedPartners = sort ? sortedToUse : typeToUse; // sorted or vanilla

  return (
    <table className={styles.table}>
      {/* table headings */}
      <thead>
        <tr className={styles.tableRow}>
          <th className={styles.tableHeading}>Lender</th>
          <th className={`${styles.tableHeading} ${styles.minMaxHeader}`}>Loan Amount</th>
          <th className={`${styles.tableHeading} ${styles.termHeader}`}>Loan Term</th>
          <th className={styles.tableHeading}>Interest Rate (APR)</th>
          <th className={`${styles.tableHeading} ${styles.buttonHeader}`}>
            <span />
          </th>
        </tr>
      </thead>
      {/* table body */}
      <tbody className={styles.tableBody}>
        {sortedPartners.map((partner) => {
          const {
            person_interest_rate: personalInterest,
            max_interest_rate: maxInterestRate,
            personal_loan_terms: personalTerms,
            min_amount_personal: personalMin,
            max_amount_personal: personalMax,
            business_interest_rate: bizInterest,
            business_loan_terms: bizTerms,
            min_amount_business: bizMin,
            max_amount_business: bizMax,
            interest_rate: interest,
            max_personal_interest_rate: maxPersonalInterest,
            max_business_interest_rate: maxBusinessInterest,
            loan_terms: terms,
            logo,
            max_amount: max,
            min_amount: min,
            lender,
          } = partner;

          // add commas to thousands
          const formatNum = (number) => {
            if (!number || number === 0) return 'varies';
            const newNum = number.toLocaleString('en-US', { maximumFractionDigits: 2 });
            return `$${newNum}`;
          };

          let interestRateToUse = interest;
          let maxInterestRateToUse = maxInterestRate;
          let termsToUse = terms;
          let minMaxToUse = `${formatNum(min)} - ${formatNum(max)}`;

          if (isBusinessList) {
            minMaxToUse = `${formatNum(bizMin)} - ${formatNum(bizMax)}`;
            interestRateToUse = bizInterest;
            maxInterestRateToUse = maxBusinessInterest;
            termsToUse = bizTerms;
          }

          if (isPersonalList) {
            minMaxToUse = `${formatNum(personalMin)} - ${formatNum(personalMax)}`;
            interestRateToUse = personalInterest;
            maxInterestRateToUse = maxPersonalInterest;
            termsToUse = personalTerms;
          }

          return (
            <tr className={styles.tableRow} key={logo?.url}>
              <td className={styles.tableData}>
                {logo?.url && <Image className={styles.logo} image={logo} alt={lender} objectFit="contain" />}
              </td>
              <td className={`${styles.tableData} ${styles.minMax}`}>{minMaxToUse}</td>
              <td className={`${styles.tableData} ${styles.terms}`}>{termsToUse.text || 'N/A'} years</td>
              <td className={styles.tableData}>
                {`${interestRateToUse.toFixed(2)}%${
                  maxInterestRateToUse != null ? ` - ${maxInterestRateToUse?.toFixed(2)}%` : ''
                }`}
              </td>

              <td className={`${styles.tableData} ${styles.ctaButton}`}>
                <CtaLink {...primary} fallbackUrl={CtaLink?.url} fallbackText="Find my rates" />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default LenderTable;
