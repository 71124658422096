import React from 'react';
import { Section, Image, CallToAction, OnVisible, Link } from 'components';
import { ReactComponent as Headlight } from '../../images/headlight-icon.svg';
import * as styles from './styles.module.scss';

const Header = (props) => {
  const { data, location } = props;
  const { primary, items } = data;
  const {
    cta_type: ctaType,
    image,
    sheet_image: sheetImage,
    title,
    content: subtitleContent,
    is_stacked: isStacked,
    pill_shaped_image: isPill,
    cta_link: ctaLink,
    cta_text: ctaText,
  } = primary;
  // content checks
  const titleHtml = title?.html;
  const titleText = title?.text;
  const subtitleContentHtml = subtitleContent?.html;
  const hasCustomCta = ctaText?.text && ctaLink?.url;
  const imageToUse =
    sheetImage?.text?.length > 4 && typeof window !== 'undefined'
      ? JSON.parse(decodeURIComponent(sheetImage?.text))
      : image;
  // render variants
  const stackedVariant = isStacked ? styles.stackedHeader : '';
  const isHome = location?.pathname === '/' ? styles.homeVariant : '';
  const pillVariant = isPill ? styles.pillShaped : '';
  const pointerVariant =
    location?.pathname.includes('product') || location?.pathname.includes('city') ? styles.pointerVariant : '';
  const noImage = !imageToUse?.url ? styles.noImageVariant : '';
  const isFullHeight = location?.pathname === '/thank-you/' ? styles.fullHeight : '';
  const classNames = `${stackedVariant} ${isHome} ${pillVariant} ${pointerVariant} ${noImage} ${isFullHeight}`;
  return (
    <OnVisible
      visibleClassName={styles.visible}
      className={`${styles.headerSlice}  ${isStacked ? styles.stackedOuter : ''}`}
    >
      <Section sliceName="Header" className={`${styles.header} ${classNames}`} noContainer>
        <div className={styles.headerLeft}>
          <div className={styles.text}>
            {titleHtml && <div className={styles.title} dangerouslySetInnerHTML={{ __html: titleHtml }} />}
            {subtitleContentHtml && (
              <div className={styles.subtitleContent} dangerouslySetInnerHTML={{ __html: subtitleContentHtml }} />
            )}
            {/* CTA SECTION */}
            {ctaType && <CallToAction type={ctaType} className={styles.callToAction} centerAlign={isStacked} />}
            {hasCustomCta && (
              <Link className={`button ${styles.customCta} ${isStacked ? styles.centerAlign : ''}`} to={ctaLink.url}>
                {ctaText.text}
              </Link>
            )}
            {/* FEATURES */}
            {items && items.length > 1 && (
              <div className={styles.features}>
                {items.map((item) => {
                  const { feature_text: content, icon } = item;
                  return (
                    <div className={styles.feature} key={content?.text}>
                      {icon?.url && <Image className={styles.icon} image={icon} alt={content?.text} />}
                      {content?.text && (
                        <div className={styles.featureText} dangerouslySetInnerHTML={{ __html: content.html }} />
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {/* IMAGE RIGHT */}
        {imageToUse?.url && (
          <div className={styles.headerRight}>
            <Headlight className={styles.headlight} />
            <div className={styles.imageContainer}>
              <Image
                image={imageToUse?.childrenImageSharp?.[0] || imageToUse}
                alt={titleText}
                className={styles.image}
                loading="eager"
                objectFit={isPill ? 'COVER' : 'CONTAIN'}
                placeholder="none"
              />
            </div>
          </div>
        )}
      </Section>
    </OnVisible>
  );
};

export default Header;
