import React from 'react';
import { Image, Link } from 'components';
import { ReactComponent as Linkedin } from 'images/linkedin.svg';
import * as styles from './styles.module.scss';

const AuthorCard = ({ authorData, defaultAuthor }) => {
  const authorToUse = authorData?.name?.text ? authorData : defaultAuthor;
  return (
    <div className={styles.authorCard}>
      {authorToUse?.image && <Image image={authorToUse?.image} className={styles.image} />}
      <div className={styles.textContainer}>
        {authorToUse?.name?.text && (
          <div dangerouslySetInnerHTML={{ __html: authorToUse?.name?.html }} className={styles.linkedAuthor} />
        )}
        {authorToUse?.bio?.text && (
          <div dangerouslySetInnerHTML={{ __html: authorToUse?.bio?.html }} className={styles.bio} />
        )}
        {authorToUse?.linkedin?.url && (
          <Link to={authorToUse?.linkedin?.url}>
            <Linkedin className={styles.linkedinIcon} />
          </Link>
        )}
      </div>
    </div>
  );
};

export default AuthorCard;
