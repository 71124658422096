import React, { useEffect } from 'react';
import { graphql, navigate } from 'gatsby';

import queryString from 'query-string';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import { Layout, ScrollToTop, Slice } from 'components';
import * as styles from './page.module.scss';

const Page = ({ location, data }) => {
  const { prismicPage } = data;
  const { data: pageData, uid, tags } = prismicPage;

  const { search: queries } = location;

  if (queries?.indexOf('finder_motorcycle') !== -1) {
    navigate(
      'https://pages.driva.co.nz/p/bike-loans?utm_source=rate_table&utm_medium=tables&utm_campaign=finder_motorcycle'
    );
  }
  const {
    body: sliceData,
    meta_title: metaTitle,
    meta_description: metaDesc,
    open_graph_image: ogImage,
    navigation_cta_link: ctaLink,
    navigation_cta_text: ctaText,
    schema,
  } = pageData;

  const seo = {
    title: metaTitle.text,
    desc: metaDesc.text,
    banner: ogImage && ogImage.url,
    schema: schema.text,
  };

  const isFaq = location?.pathname === '/faq/';
  const isProduct = location?.pathname.includes('product') ? styles.product : '';
  const isCity = tags?.includes('City');

  useEffect(() => {
    const queryStrings = location && location.search ? queryString.parse(location.search) : {};
    const { anchor } = queryStrings;
    if (anchor) scrollToElement(anchor);
  }, [location]);

  const scrollToElement = (scrollTo) => {
    if (!scrollTo) return null;
    // remove trailing slash from querystring (form/ becomes form)
    const strippedScrollTo = scrollTo.replace(/\/$/, '');
    const element = document.getElementById(strippedScrollTo);
    if (!element) return null;
    return scrollIntoView(element, { behavior: 'smooth', block: 'start' });
  };

  const navLink = { url: ctaLink?.url, text: ctaText?.text };
  const currentState = tags[1]?.toLowerCase();

  return (
    <Layout location={location} seo={seo} uid={uid} navLink={navLink}>
      <div className={`${isProduct}`}>
        {sliceData.map((slice) => (
          <Slice key={slice.id} data={slice} location={location} isPage />
        ))}
        {isFaq && <ScrollToTop />}
      </div>
    </Layout>
  );
};

export default withPrismicPreview(Page);

export const pageQuery = graphql`
  query PageBySlug($uid: String!) {
    prismicPage(uid: { eq: $uid }) {
      _previewable
      id
      uid
      prismicId
      tags
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        ogImage: open_graph_image {
          url
        }
        schema {
          text
        }
        navigation_cta_link {
          url
        }
        navigation_cta_text {
          html
          text
        }
        body {
          ...HeaderPageFragment
          ...TwoColsFeatureListPageFragment
          ...PartnersGridPageFragment
          ...FaqAccordionPageFragment
          ...TrustPilotPageFragment
          ...CtaPageFragment
          ...TwoColsTextPageFragment
          ...CustomerStoriesPageFragment
          ...TextBlockPageFragment
          ...InfoTextPageFragment
          ...AnchorButtonsPageFragment
          ...ProductPageFragment
          ...PageAnchorPoint
          ...ThreeColsReviewsPageFragment
          ...LenderTablePageFragment
          ...PageDotPointTips
          ...WysiwygPageFragment
          ...BreakoutBoxPageFragment
          ...LenderInfoPageFragment
          ...TwoColsLoanCardPageFragment
          ...IconColumnsPageFragment
          ...PageComparisonTable
          ...iframeEmbedFragment
          ...ImageContactStepsPageFragment
          ...TextImageOverlapPageFragment
          ...SignUpFormPageFragment
          ...CalculatorPageFragment
          ...DocumentsPageFragment
          ...StackedComparisonPageFragment
          ...TwoColsImagePageFragment
          ...StatsPageFragment
          ...TickertapePageFragment
          ...LenderBubblesPageFragment
          ...ServiceBundlesPageFragment
          ...ThreeColumnCardPageFragment
          ...PartnersSignUpFormPageFragment
        }
      }
    }
    partners: prismicSettings {
      data {
        referral_partners {
          id {
            text
          }
          logo {
            url
          }
        }
      }
    }
  }
`;
