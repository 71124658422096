import React from 'react';
import { Image, Link } from 'components';
import { ReactComponent as Tick } from 'images/black-green-tick.svg';
import { ReactComponent as PriceCircles } from 'images/price-circles.svg';
import * as styles from './styles.module.scss';

const LoanCard = ({ data, className }) => {
  const { lender, price, term, settingsData } = data;
  const { partners } = settingsData;
  const findLender = partners.filter((partner) => partner?.lender === lender);
  const activeLender = findLender[0];

  // content checks
  const hasPrice = price?.text;
  const hasTerm = term?.text;
  const hasLogo = activeLender?.logo?.url;
  const interestRate = activeLender?.business_interest_rate || activeLender?.interest_rate;

  return (
    <div className={`${styles.loanCard} ${className || ''}`}>
      <div className={styles.topSection}>
        {hasLogo && <Image className={styles.logo} image={activeLender.logo} objectFit="contain" />}
        <div className={styles.topDetails}>
          {hasPrice && <span className={styles.price}>{price.text}</span>}
          {hasTerm && <span className={styles.term}>{term.text}</span>}
        </div>
      </div>
      <div className={styles.numbersGrid}>
        <div className={styles.gridSection}>
          <span className={styles.value}>{interestRate}%</span>
          <span className={styles.property}>Interest rate (p.a)</span>
        </div>
        <div className={styles.gridSection}>
          <span className={styles.value}>No</span>
          <span className={styles.property}>Early termination charge</span>
        </div>
        <div className={styles.gridSection}>
          <span className={styles.value}>0%</span>
          <span className={styles.property}>Balloon payment</span>
        </div>
      </div>
      <div className={styles.ctaSection}>
        <div className={styles.feesContainer}>
          <Tick className={styles.tick} />
          <span className={styles.feeText}>All fees included</span>
        </div>
        <Link className={`button ${styles.ctaButton}`} to="https://dash.driva.co.nz/welcome">
          Find your rates
        </Link>
        <div className={styles.priceContainer}>
          <PriceCircles className={styles.priceCircles} />
          <span className={styles.priceText}>Cheapest option</span>
        </div>
      </div>
    </div>
  );
};

export default LoanCard;
