// extracted by mini-css-extract-plugin
export var accordionContainer = "styles-module--accordionContainer--c1a64";
export var accordionItem = "styles-module--accordionItem--dc2cf";
export var accordionItemToggle = "styles-module--accordionItemToggle--52704";
export var accordionItemToggleContainer = "styles-module--accordionItemToggleContainer--081e1";
export var accordionItems = "styles-module--accordionItems--b7a7a";
export var active = "styles-module--active--090ae";
export var answer = "styles-module--answer--9abbd";
export var description = "styles-module--description--db38c";
export var faqAccordion = "styles-module--faqAccordion--bb1f5";
export var inactive = "styles-module--inactive--5da28";
export var lightBg = "styles-module--lightBg--66434";
export var question = "styles-module--question--b174e";
export var textContainer = "styles-module--textContainer--cdfc1";
export var title = "styles-module--title--309c0";
export var titleContainer = "styles-module--titleContainer--4141f";
export var twoColumns = "styles-module--twoColumns--03ca1";
export var visible = "styles-module--visible--0677e";