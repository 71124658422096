import React, { useEffect, useState } from 'react';
import { Footer, Header, SEO, NoJs, ReferralBanner, CountrySelectorPopup } from 'components';

import { useStaticQuery, graphql } from 'gatsby';
import useSessionStorage from '../../../hooks/useSessionStorage';
import '../../../sass/global/styles.scss';
import './styles.scss';

const Layout = (props) => {
  const { children, customSEO, uid, seo, location, navLink } = props;

  const referrerQuery = graphql`
    query {
      ...referrerPartners
    }
  `;

  const sessionStorage = useSessionStorage('session');

  const {
    prismicSettings: {
      data: { referral_partners: referralPartners },
    },
  } = useStaticQuery(referrerQuery);

  const [activeReferrer, setActiveReferrer] = useState(null);

  useEffect(() => {
    // retrive queries if they exist
    const utms = sessionStorage.getItem('queriesObj');
    const parsedUtms = utms ? JSON.parse(utms) : null;
    const fromReferrer = parsedUtms && parsedUtms?.utm_campaign;

    if (fromReferrer) {
      const referrer = referralPartners.find((partner) => partner?.id?.text === fromReferrer);

      if (referrer) {
        setActiveReferrer(referrer);
      }
    }
  }, [sessionStorage]);

  return (
    <>
      <NoJs />
      {/* <WmAscii /> */}
      <Header location={location} navLink={navLink} />
      <CountrySelectorPopup location={location} />
      <main className={uid ? `template-${uid}` : ''}>
        {!customSEO && <SEO {...seo} location={location} />}
        {children}
      </main>
      <Footer />
      {activeReferrer && <ReferralBanner className="referrer-banner-comp" referrer={activeReferrer} />}
    </>
  );
};

export default Layout;
