import React, { useState, useEffect } from 'react';
import { use100vh } from 'react-div-100vh';
import { useStaticQuery, graphql } from 'gatsby';
import { Link, Logo, Hamburger } from 'components';
import { ReactComponent as Phone } from '../../../images/telephone.svg';
import { ReactComponent as Chevron } from '../../../images/down-chevron.svg';
import { ReactComponent as Login } from '../../../images/login-arrow.svg';
import * as styles from './styles.module.scss';

const Header = ({ location, navLink }) => {
  const [showNavBurger, setShowNavBurger] = useState(false);
  const [scrollTop, setScrollTop] = useState(0); // set pixels to - from top when shownav = false
  const [bodyLocked, setBodyLock] = useState(false); // lock body while show nav = true

  // LINKS DATA FROM SETTINGS
  const headerQuery = graphql`
    query {
      ...GlobalHeaderLinks
    }
  `;

  const {
    prismicSettings: { data: headerNavigationDataRaw },
  } = useStaticQuery(headerQuery);

  // FORMAT RAW LINK DATA
  const headerNavData = [];

  [1, 2, 3, 4, 5].forEach((count) => {
    headerNavData.push({
      headerNavLink: headerNavigationDataRaw[`header_navigation_link_${count}`],
      headerNavLinkUrl: headerNavigationDataRaw[`header_navigation_link_${count}_url`],
      headerNavLinkSublinks: headerNavigationDataRaw?.[`header_navigation_link_${count}_sublinks`],
    });
  });

  /// H A M B U R G E R   L O G I C /////////////////////

  // toggle main nav links for mobile
  const toggleNav = (event) => {
    event.preventDefault();
    setShowNavBurger(!showNavBurger);
  };

  // C L O S E   O N   E S C   A N D   B O D Y   L O C K
  const handleEscKey = (event) => {
    // get rid of nav on esc keydown
    if (event.keyCode === 27) {
      document.body.classList.remove('nav-open');
      setShowNavBurger(false);
    }
  };

  const lockBody = () => {
    // lock body while show nav true
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop;
    setScrollTop(scrollPosition);
    document.body.style.top = `-${scrollPosition}px`;
    document.body.classList.add('nav-open');
    setBodyLock(true);
  };

  const unlockBody = () => {
    // when show nav false, unlock body
    document.body.classList.remove('nav-open');
    document.body.style.top = '0px';
    window.scrollTo(0, scrollTop);
    setScrollTop(0);
    setBodyLock(false);
  };

  // use effect exit menu on sec keydown
  useEffect(() => {
    document.addEventListener('keydown', handleEscKey);
    return () => {
      unlockBody();
      document.removeEventListener('keydown', handleEscKey);
    };
  }, []);

  // use effect when shownav updated
  useEffect(() => {
    if (showNavBurger && !bodyLocked) lockBody();
    if (!showNavBurger && bodyLocked) unlockBody();
  }, [showNavBurger]);

  // close nav if current page is selected from nav
  const handleClick = (event) => {
    event.preventDefault();
    setShowNavBurger(false);
  };

  // toggle visibility for mobile
  const hamburgerOpenClass = showNavBurger ? styles.hamburgerOpen : '';

  const height = use100vh();

  const ctaData = {
    text: navLink?.text || 'Get Started',
    url: navLink?.url || 'https://dash.driva.co.nz/welcome',
  };

  return (
    <header className={`${styles.header} ${hamburgerOpenClass || ''}`}>
      <div className={`container ${styles.container || ''}`}>
        {/* NAV */}
        <div className={styles.navigationContainer} style={showNavBurger ? { height } : {}}>
          <div className={styles.navLeft}>
            <div className={styles.logoContainer}>
              <Logo />
            </div>
            <Navigation data={headerNavData} handleClick={handleClick} location={location} />
          </div>
          {/* CTAS */}
          <div className={styles.navRight}>
            <div className={styles.phoneNumber}>
              <a href="tel:0800033163" className={styles.numberIcon}>
                <Phone className={styles.icon} />
              </a>
              <a href="tel:0800033163" className={styles.number}>
                0800 033 163
              </a>
            </div>
            <div className={styles.loginContainer}>
              <Link to="https://dash.driva.co.nz/login" className={styles.iconLink}>
                <Login className={styles.icon} />
              </Link>
              <Link to="https://dash.driva.co.nz/login" className={styles.login}>
                Login
              </Link>
            </div>
            {ctaData && (
              <Link to={ctaData.url} className={`button ${styles.getStarted}`}>
                {ctaData.text}
              </Link>
            )}
          </div>
          <Hamburger onClick={toggleNav} active={showNavBurger} />
        </div>
      </div>
    </header>
  );
};

// links component
const Navigation = ({ data, handleClick, location }) => {
  const [activeSublinksIndex, setActiveSublinksIndex] = useState(false);
  // toggle sub links for mobile
  const showNavSublinks = (event, index) => {
    event.preventDefault();
    if (activeSublinksIndex === index) {
      setActiveSublinksIndex(false);
    }
    if (activeSublinksIndex !== index) {
      setActiveSublinksIndex(index);
    }
  };

  return (
    <nav className={styles.nav}>
      {data.map((section, index) => {
        const { headerNavLink: linkText, headerNavLinkSublinks: sublinks, headerNavLinkUrl: linkUrl } = section;
        const isActive = activeSublinksIndex === index;
        const hasSublinks = sublinks?.length > 0;
        const chevronToggle = isActive ? styles.rotateChevron : '';
        const sublinksActive = isActive ? styles.sublinksActive : '';
        return (
          <div key={linkText?.text} className={styles.navItem}>
            <Link
              className={styles.parentLink}
              to={linkUrl?.url}
              onClick={linkUrl?.url ? () => {} : (event) => showNavSublinks(event, index)}
            >
              {linkText?.text}
              {hasSublinks && (
                <button
                  type="button"
                  className={`${styles.chevronToggle} ${chevronToggle}`}
                  onClick={(event) => showNavSublinks(event, index)}
                >
                  <Chevron className={styles.chevron} />
                </button>
              )}
            </Link>

            {hasSublinks && (
              <div className={`${styles.sublinks} ${sublinksActive}`}>
                {sublinks.map((sublink) => {
                  const { link, text } = sublink;
                  return (
                    <Link
                      key={text.text}
                      className={styles.sublink}
                      to={link.url}
                      activeClassName={styles.activeSublink}
                      onClick={location?.pathname === link?.url ? handleClick : () => {}}
                    >
                      {text?.text}
                    </Link>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </nav>
  );
};

export default Header;
