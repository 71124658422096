import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Section, Image, Link, OnVisible, LoanCard } from 'components';
import { ReactComponent as Circle1 } from '../../images/green-circle-1.svg';
import { ReactComponent as Circle2 } from '../../images/green-circle-2.svg';
import { ReactComponent as Circle3 } from '../../images/green-circle-3.svg';
import { ReactComponent as Circle4 } from '../../images/green-circle-4.svg';

import * as styles from './styles.module.scss';

const TwoColsLoanCard = (props) => {
  const { data } = props;
  const { primary, items } = data;
  const {
    image,
    lender,
    lender_card_price: price,
    lender_card_price_term: term,
    align_image_right: alignRight,
    title,
    content,
    cta_link: ctaLink,
    cta_text: ctaText,
  } = primary;

  const settingsQuery = graphql`
    query {
      ...partnerData
    }
  `;

  const { prismicSettings } = useStaticQuery(settingsQuery);
  const { data: lenderData } = prismicSettings;
  const loanCardData = {
    lender,
    price,
    term,
    settingsData: lenderData,
  };

  // content checks
  const hasLoanCard = !!(lender?.text && price?.text && term?.text);
  const hasImage = image?.url;
  const titleHtml = title?.html;
  const contentHtml = content?.html;
  const hasCta = ctaLink?.url && ctaText?.text;

  const circleIcons = {
    0: <Circle1 className={styles.greenCircle} />,
    1: <Circle2 className={styles.greenCircle} />,
    2: <Circle3 className={styles.greenCircle} />,
    3: <Circle4 className={styles.greenCircle} />,
  };

  return (
    <Section
      sliceName="TwoColsLoanCard "
      className={`two-cols-loan-card ${styles.twoColsLoanCard} ${alignRight ? styles.alignImageRight : ''} ${
        hasLoanCard ? '' : styles.noLoanCard
      }`}
      containerClassName={styles.container}
    >
      {titleHtml && <div dangerouslySetInnerHTML={{ __html: titleHtml }} className={styles.title} />}
      {contentHtml && <div dangerouslySetInnerHTML={{ __html: contentHtml }} className={styles.content} />}
      <div className={styles.gridContainer}>
        <OnVisible className={styles.leftCol} visibleClassName={styles.visibleLeftCol}>
          {hasImage && <Image className={styles.image} image={image} objectFit="cover" />}
          {hasLoanCard && <LoanCard data={loanCardData} className={styles.loanCard} />}
        </OnVisible>
        <div className={styles.rightCol}>
          {items.map((item, index) => {
            const { step_content: stepContent, step_title: stepTitle } = item;
            return (
              <OnVisible
                key={stepTitle?.text}
                className={styles.stepContainer}
                visibleClassName={styles.visStepContainer}
              >
                <div className={styles.iconContainer}>{circleIcons[index]}</div>
                <div className={styles.stepText}>
                  {stepTitle?.text && (
                    <div className={styles.stepTitle} dangerouslySetInnerHTML={{ __html: stepTitle?.html }} />
                  )}
                  {stepContent?.text && (
                    <div className={styles.stepContent} dangerouslySetInnerHTML={{ __html: stepContent?.html }} />
                  )}
                </div>
              </OnVisible>
            );
          })}
        </div>
      </div>
      {hasCta && (
        <Link to={ctaLink.url} className={`button ${styles.cta}`}>
          {ctaText?.text}
        </Link>
      )}
    </Section>
  );
};

export default TwoColsLoanCard;
